import React from 'react';
import { Helmet } from 'react-helmet';
import { RichText } from 'prismic-reactjs';

import { getNode } from '../utils/graphql';

import '../vendor/bootstrap/css/bootstrap.min.css';
import '../vendor/fontawesome-free/css/all.min.css';
import '../css/coming-soon.min.css';

const ComingSoon = ({ data, pageName }) => {
  const node = getNode(data, pageName);

  return (
    <>
      <Helmet>
        <title>{RichText.asText(node.title)}</title>
        <meta name="description" content={RichText.asText(node.description)} />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i" />
        <link href="https://fonts.googleapis.com/css?family=Merriweather:300,300i,400,400i,700,700i,900,900i" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css?family=Merriweather:300,300i,400,400i,700,700i,900,900i" rel="stylesheet" />
      </Helmet>
      <div className="overlay" />

      <video playsinline autoPlay muted loop>
        <source src={node.background_video.url} type="video/mp4" />
      </video>

      <div className="masthead">
        <div className="masthead-bg" />
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-12 my-auto">
              <div className="masthead-content text-white py-5 py-md-0">
                {RichText.render(node.content)}
                <div className="input-group input-group-newsletter">
                  <input type="email" className="form-control" placeholder={RichText.asText(node.input)} aria-label={RichText.asText(node.input)} aria-describedby="basic-addon" />
                  <div className="input-group-append">
                    <button className="btn btn-secondary" type="button">{RichText.asText(node.button)}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="social-icons">
        <ul className="list-unstyled text-center mb-0">
          <li className="list-unstyled-item">
            <a href={node.facebook.url} target="_blank" rel="noreferrer">
              <i className="fab fa-facebook-f" />
            </a>
          </li>
          <li className="list-unstyled-item">
            <a href={node.instagram.url} target="_blank" rel="noreferrer">
              <i className="fab fa-instagram" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

ComingSoon.defaultProps = {
  pageName: 'comingsoon',
};

export default ComingSoon;

import React from 'react';
import { graphql } from 'gatsby';
// import { RichText } from 'prismic-reactjs';

import { getNode } from '../utils/graphql';
import SEO from '../components/SEO';
import ComingSoon from '../components/ComingSoon';

export const query = graphql`
  query HomePage($locale: String) {
    prismic {
      allHomes(lang: $locale) {
        edges {
          node {
            title
            content
          }
        }
      }
      allComingsoons(lang: $locale) {
        edges {
          node {
            name
            background_video {
              ... on PRISMIC__FileLink {
                url
              }
            }
            content
            input
            button
            title
            description
            facebook {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            instagram {
              ... on PRISMIC__ExternalLink {
                url
              }
            }
          }
        }
      }
    }
  }
`;

const HomePage = ({ data, pageName, pageContext: { lang } }) => {
  const node = getNode(data, pageName); // eslint-disable-line

  return (
    <>
      <SEO
        lang={lang}
      />
      <ComingSoon data={data} />
    </>
  );
};

HomePage.defaultProps = {
  pageName: 'home',
};

export default HomePage;

import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = (props) => {
  const {
    lang, title, description, keywords,
  } = props;

  return (
    <Helmet htmlAttributes={{ lang }} defer={false}>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
};

export default SEO;
